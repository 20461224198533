import React, {useState} from 'react'

export default () => {
    const [isSkillsShown, setIsSkillsShown] = useState(false);

    return (
        <div className="d-md-flex h-md-100 align-items-center">
            <div className="col-md-6 p-0 bg-indigo h-md-100 with-background-image">
                <div className="d-md-flex align-items-center h-100 p-5 text-center justify-content-center">
                    <div className=" pt-5 pb-5">
                        <p>
                            James Bowyer, <span style={{color: "rgba(255,255,255,0.7)"}}>
                        <i className="fa fa-map-marker" aria-hidden="true"/> Brighton
                    </span>
                        </p>
                        <p>
                            <a target="_blank" className="styled-link" href="https://www.linkedin.com/in/james-bowyer-sussex/">
                                <i className="fab fa-linkedin-square"/>
                            </a>&nbsp;
                            <a target="_blank" className="styled-link" href="https://www.facebook.com/sambowye">
                                <i className="fab fa-facebook-square"/>
                            </a>&nbsp;
                            <a target="_blank" className="styled-link" href="mailto:james@bowyer.dev">
                                <i className="fas fa-envelope-square"/>
                            </a>
                        </p>
                        <p>
                            <span style={{fontSize: "3em"}}>Fullstack Developer</span>
                        </p>
                        <hr/>

                        <Profile/>
                    </div>
                </div>
            </div>

            <div className="col-md-6 p-0 bg-white h-md-100">
                <div className="text-center" style={{fontSize:"3em"}}>
                    <img src="profilePhoto.png" style={{maxHeight: "80px"}}/> How can I help you?
                </div>

                <div className="text-center">Next Available: September</div>
                <br/>

                <div className="d-md-flex align-items-center h-md-100 p-5 justify-content-center">

                    <div style={{display:(isSkillsShown) ? "none" : "block"}}>
                        Key Ability In:
                        <span className="badge badge-info">React.js</span>
                        <span className="badge badge-info">Python (Flask/Django)</span>
                        <span className="badge badge-info">PHP (Laravel)</span>
                        <br />
                        Key Soft Skills:
                        <span className="badge badge-info">Interface Design</span>
                        <span className="badge badge-info">Server Management (Linux)</span>
                        <br />
                        <p>
                        <u><a style={{cursor:"pointer"}} onClick={() => setIsSkillsShown(!isSkillsShown)}>View All</a></u>
                        </p>
                    </div>
                    <SkillsTable isShown={isSkillsShown} /><br />
                    <u style={{display:(false === isSkillsShown) ? "none" : "block"}}><a style={{cursor:"pointer"}} onClick={() => setIsSkillsShown(!isSkillsShown)}>Hide</a></u>

                </div>
                <div className="text-center">

                <a target="_blank"  href="mailto:james@bowyer.dev">
                    <i style={{color:"black"}} className="fas fa-envelope"/> <u style={{color:"black"}}>Contact me</u>
                </a>

                </div>
            </div>
        </div>
    );
}


const Profile = () => {
    return (
        <div>
            <div className="section">
                <h2>
                    <i className="fa fa-briefcase" aria-hidden="true"/>&nbsp;Experienced
                </h2>
                <li>
                    7 year portfolio <span style={{color: "rgba(255,255,255,0.7)"}}>(LAMP, MERN & Python)</span>
                </li>
                <li>
                    Tailored simple communication <u style={{display:"none"}}>(more)</u>
                </li>
                <li>
                    Successful end-to-end Projects
                </li>
            </div>
            <div className="section">
                <h2>
                    <i className="fa fa-graduation-cap"/>&nbsp;Qualified</h2>

                    <li>1st BSc <b>Computer Science</b>, University of Sussex</li>
                    <li>MSc Psychology (Online Behaviour Dissertation)</li>
                    <li>British Computer Society Member</li>
            </div>
            <div className="section">
                <h2><i className="fa fa-lightbulb" aria-hidden="true"/>&nbsp;Proactive</h2>
                    <li>Integrate with your team quickly</li>
                    <li>+ scope & execute solo deliverables</li>
                    <li>= Renewed Prior Contracts</li>

            </div>
        </div>
    )
};

const SkillsTable = ({isShown}) => (
    <table style={{display: (isShown) ? "block" : "none"}}
           className="table table-striped table-hover">
        <tr>
            <td>Advanced</td>
            <td>
                <span className="badge badge-secondary">React.js</span>
                <span className="badge badge-secondary">Python</span>
                <span className="badge badge-secondary">MySQL</span>
                <span className="badge badge-secondary">Git</span>
                <span className="badge badge-secondary">PHP</span>
                <span className="badge badge-secondary">Javascript</span>
                <span className="badge badge-secondary">Linux Server Admin</span>
                <span className="badge badge-secondary">HTML/CSS/Bootstrap</span>
            </td>
        </tr>
        <tr>
            <td>Multiple Projects</td>
            <td>
                <span className="badge badge-secondary">PyTorch</span>
                <span className="badge badge-secondary">Spacy/OpenCV/OfficeGen</span>
                <span className="badge badge-secondary">MongoDB (with mongoose)</span>
                <span className="badge badge-secondary">Wordpress/Laravel/Symfony</span>
                <span className="badge badge-secondary">Socket.io/Server Sent Events</span>
            </td>
        </tr>
        <tr>
            <td>Capable</td>
            <td>
                <span className="badge badge-secondary">R</span>
                <span className="badge badge-secondary">C</span>
                <span className="badge badge-secondary">Matlab</span>
                <span className="badge badge-secondary">Vue.js</span>
                <span className="badge badge-secondary">Sklearn</span>
                <span className="badge badge-secondary">Java</span>
                <span className="badge badge-secondary">NLTK</span>
            </td>
        </tr>
    </table>
);

