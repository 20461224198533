import React from 'react';
import { hot } from 'react-hot-loader/root';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import NotFoundPage from "./pages/NotFoundPage";
import {BrowserRouter, Route, Switch} from "react-router-dom";


function App() {

  return (
      <BrowserRouter>
          <Switch>
              <Route exact path="/projects" component={Projects} />} />
              <Route exact path="/" component={Home} />} />
              <Route exact path="/notFound" component={NotFoundPage} />
          </Switch>
      </BrowserRouter>
  );
}

/** We export hot, so that component level changes can update "in place" on the browser,
 * using react-hot-loader. This massively speeds up computation compared to reloading each module
 * for the complete SPA reload that Webpack Hot Loader would otherwise trigger on save changes (for dev config)
 */
export default hot(App);
